// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cakes-js": () => import("./../../../src/pages/cakes.js" /* webpackChunkName: "component---src-pages-cakes-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-cupcakes-js": () => import("./../../../src/pages/cupcakes.js" /* webpackChunkName: "component---src-pages-cupcakes-js" */),
  "component---src-pages-flavours-js": () => import("./../../../src/pages/flavours.js" /* webpackChunkName: "component---src-pages-flavours-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-nofun-js": () => import("./../../../src/pages/nofun.js" /* webpackChunkName: "component---src-pages-nofun-js" */),
  "component---src-pages-orphans-js": () => import("./../../../src/pages/orphans.js" /* webpackChunkName: "component---src-pages-orphans-js" */)
}

